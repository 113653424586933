import React from 'react'
import PropTypes from 'prop-types'
import { LocationProvider, LocationProviderProps } from 'components/location-provider/location-provider'
import { MediaQueryProvider, MediaQueryProviderProps } from 'components/media-query-provider/media-query-provider'
import { ReadSpeakerProvider } from 'components/read-speaker-provider/read-speaker-provider'

type ProviderComposerProps = {
	children: PropTypes.ReactElementLike
	contexts: PropTypes.ReactElementLike[]
}

const ProviderComposer = ({ contexts, children }: ProviderComposerProps) => {
	return contexts.reduceRight(
		(kids, parent) =>
			React.cloneElement(parent, {
				children: kids,
			}),
		children
	)
}

type ContextProviderProps = LocationProviderProps & MediaQueryProviderProps & { children: PropTypes.ReactElementLike }

export const ContextProvider = ({ children, language, origin, isMobileDevice = false }: ContextProviderProps) => {
	return (
		<ProviderComposer
			contexts={[
				<LocationProvider key="1" language={language} origin={origin}>
					{children}
				</LocationProvider>,
				<MediaQueryProvider key="2" isMobileDevice={isMobileDevice}>
					{children}
				</MediaQueryProvider>,
				<ReadSpeakerProvider key="3">{children}</ReadSpeakerProvider>,
			]}>
			{children}
		</ProviderComposer>
	)
}
