import React, { useEffect } from 'react'
import App, { AppProps, AppContext } from 'next/app'
import { SWRConfig } from 'swr'
import { ThemeProvider } from 'styled-components'
import { UAParser } from 'ua-parser-js'
import { lightTheme } from 'theme/theme'
import { GlobalStyles } from 'theme/global-styles'
import { getUserAgent, getLanguage, getLocationOrigin } from 'utils/url'
import { ContextProvider } from 'components/context-provider/context-provider'
import { Language } from 'generated/sdk'
import { ErrorBoundary } from 'services/sentry'
import 'intersection-observer'
import 'cross-fetch/polyfill'
import 'theme/bootstrap.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'theme/global.scss'
import 'theme/leaflet.scss'
import 'theme/cookiepro/cookie-banner.scss'
import 'theme/cookiepro/cookie-details.scss'
import 'theme/cookiepro/cookie-settings.scss'

type MyAppProps = {
	isMobile: boolean
	language: Language
	origin: string
}

const MyApp = ({ Component, pageProps, language, origin, isMobile }: AppProps & MyAppProps) => {
	useEffect(() => {
		document.documentElement.lang = language
	}, [language])

	const MyAppComponent = (
		<ThemeProvider theme={lightTheme}>
			<GlobalStyles />
			<SWRConfig
				value={{
					shouldRetryOnError: true,
					errorRetryCount: 2,
					focusThrottleInterval: 60 * 1000, // 1 minute
					refreshInterval: 7 * 60 * 1000, // 7 minutes
				}}>
				<ContextProvider language={language} origin={origin} isMobileDevice={isMobile}>
					<Component {...pageProps} />
				</ContextProvider>
			</SWRConfig>
		</ThemeProvider>
	)

	if (ErrorBoundary) {
		return <ErrorBoundary>{MyAppComponent}</ErrorBoundary>
	}
	return MyAppComponent
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	const language = getLanguage(appContext.ctx.query)
	const userAgent = getUserAgent(appContext.ctx.req)
	const origin = getLocationOrigin(appContext.ctx.req)
	const device = new UAParser(userAgent).getDevice()
	const appProps = await App.getInitialProps(appContext)
	return { ...appProps, language, origin, isMobile: device.type === 'mobile' }
}

export default MyApp
