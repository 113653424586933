import { createGlobalStyle } from 'styled-components'
import { helpers } from './helpers'

export const GlobalStyles = createGlobalStyle`
	body {
		font-family: "Regular", Arial, Helvetica Neue, Helvetica, sans-serif;

		/* https://gist.github.com/hsleonis/55712b0eafc9b25f1944 */
		font-size: 100%;
		text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
		font-variant-ligatures: none;
		text-rendering: optimizeLegibility;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
	}

	.rs_addtools,
	.rsbtn {
		padding-bottom: 0 !important;
	}

	${helpers}
`
