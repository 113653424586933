import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export type ReadSpeakerProviderProps = {
	children: PropTypes.ReactNodeLike
}
type ContextProps = {
	isReadSpeakerLoaded: boolean
	setIsReadSpeakerLoaded: React.Dispatch<React.SetStateAction<boolean>>
}

const getReadeSpeaker = () => {
	if (typeof window !== 'undefined' && typeof ReadSpeaker !== 'undefined') {
		return !!ReadSpeaker
	}
	return false
}

export const ReadSpeakerContext = createContext<ContextProps>({
	isReadSpeakerLoaded: getReadeSpeaker(),
	setIsReadSpeakerLoaded: () => null,
})

export const ReadSpeakerProvider = ({ children }: ReadSpeakerProviderProps) => {
	const [isReadSpeakerLoaded, setIsReadSpeakerLoaded] = useState(() => getReadeSpeaker())
	const value = useMemo(() => ({ isReadSpeakerLoaded, setIsReadSpeakerLoaded }), [isReadSpeakerLoaded])

	return <ReadSpeakerContext.Provider value={value}>{children}</ReadSpeakerContext.Provider>
}

export const useReadSpeakerContext = () => useContext(ReadSpeakerContext)
